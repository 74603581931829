<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character2">
							<div class="text_guide pt-20">
								<h4>PIN 번호가 변경되었습니다</h4>
							</div>
						</div>
					</div>
				</div>
				<div class="intro_btn_wrap">
					<div class="btn_area">
						<button
							@click="$emit('to', {name: 'myPage'})"
							class="btn_l btn_fill_blue"
						>확인</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia017'
		, data: function(){
			return {
				program: {
					name: 'PIN변경 완료'
					,not_header: true
					,not_footer: true
				}
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
		}
	}
</script>